@import 'src/variables';

.LockHistory {
    width: 100%;

    @media screen and (min-width: $media375) {
        margin-bottom: 20px;
    }

    @media screen and (min-width: $media1024) {
        margin-bottom: 0;
    }

    @media screen and (min-width: $media1440) {
    }

    @media screen and (min-width: $media1920) {
    }

    &__Title {
        @media screen and (min-width: $media375) {
            font-size: 14px;
            line-height: 18px;
        }

        @media screen and (min-width: $media1024) {
            font-size: 11px;
            line-height: 15px;
        }

        @media screen and (min-width: $media1440) {
            font-size: 13px;
            line-height: 17px;
        }

        @media screen and (min-width: $media1920) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .header {
        display: flex;
        // justify-content: space-between;
        font-size: 13px;
        gap: 10px;
        margin-top: 5px;

        > div {
            // text-align: center;
            font-size: 13px;

            &:first-child {
                width: 20%;
            }

            &:last-child {
                // width: 50%;
            }
        }
    }

    &__List {
        overflow: hidden;
        overflow-y: auto;
        margin-top: 10px;

        @media screen and (min-width: $media375) {
            height: 200px;
        }

        @media screen and (min-width: $media1024) {
            height: 100px;
        }

        &-Item {
            border-bottom: solid 1px #D0D0D0;
            font-size: 13px;
            line-height: 17px;

            @media screen and (min-width: $media375) {
                padding: 10px 0;
            }

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            

            > div:first-child {
                width: 20%;
            }

            // > div {
            //     width: 33%;

            //     &:first-child {
            //         width: 40%;
            //     }
            // }

            .amount,
            .lock-period {
                font-weight: 600;
            }
        }
    }

    .empty {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #b4b4b4;
        // width: 200px;
        margin: 10px auto 0 auto;

        svg {
            margin-bottom: 10px;
        }
    }

    .unlock-with-penalty {
        font-size: 12px;
        line-height: 12px;
        background-color: transparent;
        border: none;
        color: #5B5959;
        border-bottom: solid 1px #5B5959;
        padding-bottom: 4px;
        text-decoration: none;
        cursor: pointer;
    }

    .lock-btn,
    .withdraw-btn {
        // width: 64px;
        // height: 25px;
        font-size: 12px;
        margin: 0;
        padding:5px 20px;
    }

    .withdraw-btn {
        margin-left: 0;
    }
}
